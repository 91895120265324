
import { IAccessibleViewImplentation } from 'vs/platform/accessibility/browser/accessibleViewRegistry';

export class HoverAccessibleView implements IAccessibleViewImplentation {
}

export class HoverAccessibilityHelp implements IAccessibleViewImplentation {
}

export class ExtHoverAccessibleView implements IAccessibleViewImplentation {
}
